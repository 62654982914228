/* Signika Negative */
@import url("https://fonts.googleapis.com/css2?family=Signika+Negative:wght@700&display=swap");

/* Barlow Condensed and Barlow */
@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html {
    box-sizing: border-box;
    height: 100%;
    overflow-y: auto;
    overscroll-behavior-y: none;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

:root {
    --primary: #060b26;
    --secondary: #1a1f39;
    --accent1: #0084f4;
    --accent2: #005ead;
    --accent3: #004177;
    --hightlight: #ffffff;
    --backgroundLinear1: #003663;
    --backgroundLinear2: #090e38;
    --text-primary: #ffffff;
    --text-secondary: blue;
    --increase: #2ac670;
    --decrease: #ff6252;
    --success: #2ac670;
    --warning: #ff6252;

    --grey1: #504c5c;
    --iconColor: #9da0a7;
    --iconColor2: #7a7784;
    --iconColor3: #beaeff;
    --textColor: #cacaca;
    --spanTextColor: #ffe664;
    --inputText: #fbfcff;
    --inputBgColor: #1b1b23;
    --loadingButtonBg: #504c5c;
    --spotifyText: #1ed760;
    --redColor: #ff3d57;
    --greyIcon: #b0b0b8;
    --borderColor: #dcdce0;
    --sliderContainerBg: #404040;
    --boxContainerBg: #968dfd;
    --confirmMessage: #dd42ce;
    --errorColor: #ff8484;
    --circularProgress: #ced1d9;
    --pinkButtonBgGradient: linear-gradient(45deg, #f0153f 5.13%, #dd42ce 100.27%);

    --backgroundColor: linear-gradient(var(--backgroundLinear1) 4.65%, var(--backgroundLinear2) 95.61%);
    --chartBackgroundColor: linear-gradient(180deg, #060b26 0%, rgba(6, 11, 38, 0.5) 100%);
    --device: 480px;
    --desktop: 1200px;
    --tablet: 768px;
    --mobile: 480px;
}

body {
    margin: 0;
    font-family:
        "Barlow",
        "Barlow Condensed",
        "Signika Negative",
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        "Roboto",
        "Oxygen",
        "Ubuntu",
        "Cantarell",
        "Fira Sans",
        "Droid Sans",
        "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: "source-code-pro", "Menlo", "Monaco, Consolas", "Courier New", monospace;
}

button {
    font-family: "Barlow", sans-serif;
    font-weight: bold;
    letter-spacing: 2px;
}

input {
    font-family: "Barlow", sans-serif;
}

input {
    font-family: inherit;
    font-size: 100%;
    line-height: 1;
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h5,
h6 {
    margin: 0;
    letter-spacing: 1px;
    font-family: "Barlow Condensed", sans-serif;
}

p {
    margin: 0;
    line-height: 1.3;
    letter-spacing: 1px;
    font-family: "Barlow", sans-serif;
}
